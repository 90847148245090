<template>
    <div class="orders">
        <v-row style="max-width: 1800px" class="mx-auto">
            <v-col cols="12">
                <v-card class="mx-auto pb-3 elevation-6">
                    <v-toolbar class="mb-2" height="50" color="indigo darken-6 elevation-3" dark flat>
                        <v-toolbar-title class="overline">
                            {{ $t('orders.list_of_orders', { p1: orders.length, p2: ordersComplete, p3: ordersFee }) }}
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn-toggle v-model="calendar" tile group mandatory dense>
                            <v-btn value="left" @click="onClickToday"> <v-icon>mdi-calendar</v-icon> {{ $t('orders.buttons.today') }} </v-btn>
                            <v-btn value="center" @click="onClickYesterday"> <v-icon>mdi-calendar-arrow-left</v-icon> {{ $t('orders.buttons.yesterday') }} </v-btn>
                            <v-btn value="right" @click="onClickMonth"> <v-icon>mdi-calendar-month</v-icon> {{ $t('orders.buttons.month') }} </v-btn>
                            <v-menu v-model="menu_calendar" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-btn text class="menu-button" v-on="on">
                                        <v-icon class="mr-1">mdi-calendar-multiple</v-icon>
                                        {{ $t('orders.buttons.range') }}
                                    </v-btn>
                                </template>
                                <v-date-picker range v-model="dates" locale="ru-ru" selected-items-text="Выбран период" :first-day-of-week="1" @change="onClickChangeDate"></v-date-picker>
                            </v-menu>
                        </v-btn-toggle>

                        <v-spacer></v-spacer>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <span class="tooltip-text" v-bind="attrs" v-on="on">
                                    <v-btn @click="onClickDownloadData" class="mx-4 px-2" tile icon rounded>
                                        <v-img src="../../assets/images/excel_png_office_xlsx_icon_3.png" width="40"></v-img>
                                    </v-btn>
                                </span>
                            </template>
                            <span>Выгрузка данных в таблицу</span>
                        </v-tooltip>

                        <v-btn icon @click="onClickUpdate">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <template>
                        <v-card-title class="py-0 d-flex">
                            <v-select class="mx-2 flex-grow-0" style="width: 220px;" no-data-text="Нет данных" @change="updateData" :items="filter_partners" name="partner" item-text="name" item-value="tid" v-model="filter_partner" label="По партнеру"></v-select>
                            <v-select class="mx-2 flex-grow-0" style="width: 220px;" no-data-text="Нет данных" @change="updateData" :items="filter_couriers" name="courier" item-text="name" item-value="tid" v-model="filter_courier" label="По курьеру"></v-select>
                            <v-select class="mx-2 flex-grow-0" style="width: 220px;" no-data-text="Нет данных" @change="updateData" :items="filter_order_modes" name="order_mode" v-model="filter_order_mode" label="По типу"></v-select>

                            <v-spacer></v-spacer>

                            <v-text-field class="mx-2 flex-grow-0" style="width: 340px;" v-model="search" append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details dense></v-text-field>
                        </v-card-title>

                        <v-data-table
                            dense
                            no-data-text="Нет данных"
                            no-results-text="Нет данных, попробуйте изменить запрос"
                            :footer-props="{
                                pageText: '{0} - {1} из {2}',
                                itemsPerPageText: 'Показывать на странице',
                                itemsPerPageAllText: 'все',
                                showFirstLastPage: true,
                                itemsPerPageOptions: [25, 50, 100, -1],
                            }"
                            :headers="headers"
                            :search="search"
                            :items="orders"
                            item-key="index"
                            :item-class="rowItemClass"
                            :loading="loading"
                            :loading-text="$t('common.loading')"
                            @click:row="onClickOrderEdit($event)"
                        >

                            <template v-slot:[`item.name`]="{ item }">
                                <b>{{ item.name }}</b>
                            </template>

                            <template v-slot:[`item.guest`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <td class="tooltip-text" v-bind="attrs" v-on="on">
                                            {{ getGuestStr(item.guest).substr(0, 24) }}
                                        </td>
                                    </template>
                                    <span>{{ getGuestStr(item.guest) }}</span>
                                </v-tooltip>
                            </template>

                            <template v-slot:[`item.finish_addr`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <td class="tooltip-text" v-bind="attrs" v-on="on">
                                            <span :style="item.fee == 0 || item.fee == $store.state.settings.price_default ? 'color: red' : ''">{{ item.finish_addr.substr(0, 24) }}</span>
                                        </td>
                                    </template>
                                    <span>{{ item.finish_addr }}</span>
                                </v-tooltip>
                            </template>

                            <template v-slot:[`item.fee`]="{ item }">
                                <span :style="item.fee == 0 || item.fee == $store.state.settings.price_default ? 'color: red' : ''">{{ item.fee }}</span>
                            </template>

                            <template v-slot:[`item.courier_name`]="{ item }">
                                <div class="text-no-wrap">
                                    <v-avatar size="24" class="mr-2" v-if="item.courier_name != '--'">
                                      <img
                                        alt="courier"
                                        :src="`${ config.api.delivery + 'user_avatar?tid=' + item.courier_tid }`"
                                      >
                                    </v-avatar>
                                    <b>{{ item.courier_name.length > 20 ?  item.courier_name.substr(0, 20) + '...' : item.courier_name }}</b>
                                </div>
                            </template>

                            <template v-slot:[`item.status`]="{ item }">
                                <div class="d-flex">
                                    <v-chip :color="orderStatusColorHtml(item.status)" text-color="white">
                                        <v-icon size="small" class="mr-1">{{ orderStatusIcon(item.status) }}</v-icon>
                                        {{ orderStatusStr(item.status) }}
                                    </v-chip>
                                    <v-btn class="mt-1" v-if="item.status != config.order.status.complete && (item.status != config.order.status.new || item.courier_tid)" outlined text tile x-small @click="onClickOrderStatus(item)"><v-icon small>mdi-pencil-outline</v-icon></v-btn>
                                </div>
                            </template>

                            <!-- <template v-slot:[`item.mode_time`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <td class="tooltip-text" v-bind="attrs" v-on="on">
                                            {{ item.mode.split ? item.mode.split(' ')[0] : item.mode }}
                                            <span class="ml-1">{{ item.mode.split(' ')[0] }} готов: {{ timeStrGet(item.mode_ready_time) }}, доставить: {{ timeStrGet(item.mode_order_time) }}</span>
                                        </td>
                                    </template>
                                    <span>{{ item.mode_time }}</span>
                                </v-tooltip>
                            </template> -->

                            <template v-slot:[`item.date`]="{ item }">
                                {{ timeStrGet(item.date) }} {{ item.mode.split(' ')[0] }}
                            </template>

                            <template v-slot:[`item.mode_ready_time`]="{ item }">
                                {{ timeStrGet(item.mode_ready_time) }}
                                
                            </template>

                            <template v-slot:[`item.mode_order_time`]="{ item }">
                                {{ timeStrGet(item.mode_order_time) }}
                            </template>

                            <template v-slot:[`item.date_take`]="{ item }">
                                <span class="mr-2">{{ timeStrGet(item.date_take) }}</span>
                                <v-chip v-if="item.date_take" class="px-1 mb-1" :color="timeColorHtml(item.date_take, item.mode_ready_time)" x-small label text-color="white">
                                    {{ timeError(item.date_take, item.mode_ready_time) }}
                                </v-chip>
                            </template>

                            <template v-slot:[`item.date_complete`]="{ item }">
                                <span class="mr-2">{{ timeStrGet(item.date_complete) }}</span>
                                <v-chip v-if="item.date_complete" class="px-1 mb-1" :color="timeColorHtml(item.date_complete, item.mode_order_time)" x-small label text-color="white">
                                    {{ timeError(item.date_complete, item.mode_order_time) }}
                                </v-chip>
                            </template>

                            <template v-slot:[`item.iikoOrderId`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <td class="tooltip-text" v-bind="attrs" v-on="on">
                                            <v-avatar size="24">
                                              <img
                                                alt="source"
                                                :src="iconSource(item)"
                                              >
                                            </v-avatar>
                                        </td>
                                    </template>
                                    <span>{{ tooltipSource(item) }}</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </template>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog
          v-model="dialogEdit"
          width="720"
        >

          <v-card>
            <v-card-title class="headline grey lighten-2">
              {{ dialogHeader }}
            </v-card-title>

            <v-card-text>

                <v-form>
                    <v-container>
                        <v-row>

                            <v-col cols="12">
                                <v-text-field v-model="selectedItem.guest" label="Гость"></v-text-field>
                            </v-col>

                            <v-col cols="8">
                                <v-text-field v-model="selectedItem.finish_addr" label="Адрес доставки" @input="onChangeAddress"></v-text-field>
                            </v-col>


                            <v-col cols="4" class='d-flex'>
                                <v-btn :disabled="addressUpdateFlag" :loading="addressUpdateFlag" elevation="2" class="float-right ma-0 pa-0 mr-2 mt-5" icon small @click="onClickAddressUpdate">
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                                <v-text-field v-model="selectedItem.fee" label="Цена доставки"></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field v-model="selectedItem.descr" label="Комментарий"></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-select class="flex-grow-0" style="width: 220px;" no-data-text="Нет данных" :items="couriers_list" name="courier" item-text="name" item-value="tid" v-model="selectedItem.courier_tid" label="Доставлен курьером"></v-select>
                            </v-col>

                        </v-row>
                    </v-container>
                </v-form>

            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" dark @click="onSaveDialogEdit">Сохранить</v-btn>
              <v-btn color="error" text @click="onDeleteDialogEdit">Удалить</v-btn>
              <v-btn color="primary" text @click="dialogEdit = false">Закрыть</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogStatus" width="unset">
            <v-card>
                <v-card-title class="headline grey lighten-2">
                    {{ dialogHeader }}
                </v-card-title>

                <v-card-text>
                    <div class="d-flex px-4 py-5 justify-md-space-around">

                        <v-btn
                            class="ma-2"
                            v-if="currentItem.status == config.order.status.way_guest || currentItem.status == config.order.status.way_partner || currentItem.status == config.order.status.passed"
                            :color="orderStatusColorHtml(config.order.status.new)"
                            dark
                            @click="onClickOrderNew()"
                            >Новый</v-btn>

                        <v-btn
                            class="ma-2"
                            v-if="currentItem.status == config.order.status.way_partner || currentItem.status == config.order.status.way_guest || currentItem.courier_tid"
                            :color="orderStatusColorHtml(config.order.status.complete)"
                            dark
                            @click="onClickOrderComplete()"
                        >Доставлен</v-btn>

                        <v-btn class="ma-2"
                            v-if="currentItem.status == config.order.status.forming"
                            color="error"
                            dark
                            @click="onClickOrderDelete()"
                        >Удалить</v-btn>

                    </div>
                    <v-divider></v-divider>
                    <span v-html="dialogContent"></span>
                </v-card-text>

            </v-card>
        </v-dialog>

        <span class="pb-16 d-block" />
    </div>
</template>

<script>
    import config from '../../config'
    import moment from 'moment-timezone'

    moment.locale('ru')
    moment.tz.setDefault(config.timezoneString)

    export default {
        data() {
            return {
                dates: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
                calendar: 0,
                menu_calendar: false,
                loading: false,
                search: '',
                ordersComplete: '--',
                ordersFee: '--',
                orders: [],
                partners: [],
                couriers: [],
                filter_partner: 0,
                filter_partners: [],
                filter_courier: 0,
                filter_couriers: [],
                couriers_list: [],
                filter_order_mode: 0,
                filter_order_modes: [
                    {
                        text: '< Все >',
                        value: 0,
                    },
                    {
                        text: '⏰ Предзаказ',
                        value: 1,
                    },
                    {
                        text: '🔥 Ближняк',
                        value: 2,
                    },
                ],
                headers: [
                    {
                        text: 'Сист.',
                        value: 'iikoOrderId',
                        sortable: false,
                    },
                    {
                        text: 'Название',
                        value: 'name',
                    },
                    {
                        text: 'Статус',
                        value: 'status',
                    },
                    {
                        text: 'Гость',
                        sortable: false,
                        value: 'guest',
                    },
                    {
                        text: 'Адрес доставки',
                        sortable: false,
                        value: 'finish_addr',
                    },
                    {
                        text: 'Цена',
                        value: 'fee',
                    },
                    {
                        text: 'Курьер',
                        value: 'courier_name',
                    },
                    {
                        text: 'Создан',
                        value: 'date',
                    },
                    {
                        text: 'Забрать',
                        value: 'mode_ready_time',
                    },
                    {
                        text: 'Доставить',
                        value: 'mode_order_time',
                    },
                    {
                        text: 'Забран',
                        value: 'date_take',
                    },
                    {
                        text: 'Доставлен',
                        value: 'date_complete',
                    },
                ],
                orderStatuses: [],
                timer: null,
                colorsHtml: [
                    '#2196F3', // blue
                    '#F44336', // red
                    '#4CAF50', // green
                    '#FF9800', // orange
                    '#9C27B0', // purple
                    '#3F51B5', // indigo
                    '#00BCD4', // cyan
                    '#FFEB3B', // yellow
                    '#795548', // brown
                    '#E91E63', // pink
                    '#673AB7', // deep-purple
                    '#03A9F4', // light-blue
                    '#009688', // teal
                    '#8BC34A', // light-green
                    '#FF5722', // deep-orange
                    '#607D8B', // blue-grey
                    '#9E9E9E', // grey
                    '#9E9E9E', // grey
                    '#9E9E9E', // grey
                    '#9E9E9E', // grey
                    '#9E9E9E', // grey
                    '#9E9E9E', // grey
                ],
                dialogStatus: false,
                dialogEdit: false,
                dialogHeader: '',
                dialogContent: '',
                config,
                currentItem: {},
                selectedItem: {},
                timerAddressUpdate: null,
                addressUpdateFlag: false,
                changeFlag: false,
            }
        },
        components: {},
        mounted() {
            console.log('orders start!')
            this.updateData()
        },

        beforeDestroy() {},

        destroyed() {
            return this.timer ? clearInterval(this.timer) : false
        },
        watch: {},
        computed: {
            dateRangeText() {
                return this.dates.join(' ~ ')
            },
        },
        methods: {
            addressUpdate() {
                let partner_id = 0
                const partner = this.partners.find(partner => partner.tid == this.selectedItem.partner_tid)
                if (partner) {
                    partner_id = partner.partner_id
                }
                this.$store
                    .dispatch('partner_address', { partner_id, address: this.selectedItem.finish_addr})
                    .then(res => {
                        if (res.data.success) {
                            console.log('partner_address ok', res.data)
                        } else {
                            console.log('partner_address fail')
                        }

                        this.addressUpdateFlag = false
                        this.selectedItem.fee = res.data.price
                        this.selectedItem.coordinates = JSON.stringify(res.data.coordinates)
                        this.changeFlag = true
                        console.log('this.selectedItem', this.selectedItem)
                    })
                    .catch(err => {
                        console.log(err)
                        this.addressUpdateFlag = false
                    })
            },
            onClickAddressUpdate() {
                if (this.timerAddressUpdate) {
                    clearTimeout(this.timerAddressUpdate)
                    this.timerAddressUpdate = null
                }
                this.addressUpdateFlag = true
                this.selectedItem.fee = 'Поиск...'
                this.addressUpdate()
            },
            onChangeAddress() {
                // тут так должно быть!
                this.addressUpdateFlag = true
                this.selectedItem.fee = '...'
                if (this.timerAddressUpdate) {
                    clearTimeout(this.timerAddressUpdate)
                    this.timerAddressUpdate = null
                }
                this.timerAddressUpdate = setTimeout(() => {
                    this.timerAddressUpdate = null
                    this.addressUpdateFlag = true
                    this.selectedItem.fee = 'Поиск...'
                    this.addressUpdate()
                }, 2000)
            },
            onDeleteDialogEdit() {

                console.log('this.currentItem', this.currentItem)

                    this.$store
                        .dispatch('order_delete', this.currentItem.id)
                        .then(res => {
                            if (res.data.success) {
                                console.log('order delete ok')
                                this.orders = this.orders.filter(order => order.id != this.currentItem.id)
                            } else {
                                console.log('order delete fail')
                            }
                            this.dialogEdit = false
                        })
                        .catch(err => {
                            console.log(err)
                            this.dialogEdit = false
                        })
                
            },
            onSaveDialogEdit() {
                // console.log('onSaveDialogEdit')
                // тут есть нюанс. была ситуация: заказ в статусе создается, открыли этот диалог, в боте поменяли статус заказа на новый, диалог закрыли -> статус заказа обновился с новый на создается и при этом заказ пропадает в боте в списке необработанных заказов, теперь сюда внесены изменения по проверке изменения полей и обновлению заказа в fgb только при изменении этих полей. Но это не решает проблему до конца, так как если поля поменяют, то произойдет так же пропажа заказа в боте. решение - обновлять только поля guest, finish_addr и fee, но для этого требуется в api поменять функцию updateOrder

                if (this.currentItem.guest != this.selectedItem.guest) {
                    this.currentItem.guest = this.selectedItem.guest
                    this.changeFlag = true
                }
                if (this.currentItem.finish_addr != this.selectedItem.finish_addr) {
                    this.currentItem.finish_addr = this.selectedItem.finish_addr
                    this.changeFlag = true
                }
                if (this.currentItem.descr != this.selectedItem.descr) {
                    this.currentItem.descr = this.selectedItem.descr
                    this.changeFlag = true
                }
                if (this.currentItem.coordinates != this.selectedItem.coordinates) {
                    this.currentItem.coordinates = this.selectedItem.coordinates
                    this.changeFlag = true
                }
                if (this.currentItem.courier_tid != this.selectedItem.courier_tid) {
                    this.currentItem.courier_tid = this.selectedItem.courier_tid
                    this.changeFlag = true
                    this.currentItem.courier_name = this.couriers_list.find(c => c.tid == this.currentItem.courier_tid).name
                    if (this.currentItem.courier_name == '< Не назначен >') {
                        this.currentItem.courier_name = '--'
                    }
                }

                // console.log('this.selectedItem.fee', this.selectedItem.fee)

                const fee = parseInt(this.selectedItem.fee)
                if (fee || fee == 0) {
                    if (this.selectedItem.date_complete) {
                        this.ordersFee += fee - this.currentItem.fee
                    }
                    this.currentItem.fee = fee
                    this.changeFlag = true
                }
                this.dialogEdit = false

                if (this.changeFlag) {
                    this.$store
                        .dispatch('order_set', this.currentItem)
                        .then(res => {
                            if (res.data.success) {
                                console.log('order saved ok')
                            } else {
                                console.log('order saved fail')
                            }
                            this.changeFlag = false
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }
                
            },
            onClickOrderEdit(item) {
                if (this.dialogStatus) return
                if (item.status == config.order.status.forming) return
                this.dialogHeader = `Заказ ${item.name}`
                this.currentItem = item
                this.selectedItem = JSON.parse(JSON.stringify(item))
                this.dialogEdit = true
            },
            onClickOrderStatus(item) {
                this.dialogHeader = `Изменить статус для ${item.name}`
                this.dialogContent = `Текущий статус: <span style="color: ${this.orderStatusColorHtml(item.status)};">${this.orderStatusStr(item.status)}</span>`
                this.currentItem = item
                this.dialogStatus = true
            },
            onClickOrderNew() {
                let courier = this.couriers.find(courier => courier.tid == this.currentItem.courier_tid)
                if (courier) {
                    if (courier.act_ord) {
                        courier.act_ord--
                    }
                    if (courier.act_ord == 0) {
                        courier.status = config.user.status.free
                    }
                    this.$store.dispatch('user_set', courier).then(res => {
                        if (res.data.success) {
                            console.log('user saved ok')
                        } else {
                            console.log('order saved fail')
                        }
                    })
                }

                this.currentItem.status = config.order.status.new
                this.currentItem.courier_tid = null

                this.$store
                    .dispatch('order_set', this.currentItem)
                    .then(res => {
                        if (res.data.success) {
                            console.log('order saved ok')
                            this.orders = this.orders.map((order, i) => {
                                if (order.id == this.currentItem.id) {
                                    order.status = this.currentItem.status
                                    order.courier_tid = this.currentItem.courier_tid
                                }
                                return order
                            })
                        } else {
                            console.log('order saved fail')
                        }
                        this.dialogStatus = false
                    })
                    .catch(err => {
                        console.log(err)
                        this.dialogStatus = false
                    })
            },
            onClickOrderComplete() {
                let courier = this.couriers.find(courier => courier.tid == this.currentItem.courier_tid)

                if (courier) {
                    if (courier.act_ord) {
                        courier.act_ord--
                    }
                    if (courier.act_ord == 0) {
                        courier.status = config.user.status.free
                    }

                    this.$store.dispatch('user_set', courier).then(res => {
                        if (res.data.success) {
                            console.log('user saved ok')
                        } else {
                            console.log('order saved fail')
                        }
                    })

                    this.currentItem.status = config.order.status.complete
                    if (!this.currentItem.date_take) {
                        this.currentItem.date_take = this.currentItem.mode_ready_time
                    }
                    if (!this.currentItem.date_complete) {
                        this.currentItem.date_complete = this.currentItem.mode_order_time
                    // } else {
                    //     this.currentItem.date_complete = moment().format('YYYY-MM-DD HH:mm:ss')
                    }

                    this.$store
                        .dispatch('order_set', this.currentItem)
                        .then(res => {
                            if (res.data.success) {
                                console.log('order saved ok')
                                this.orders = this.orders.map((order, i) => {
                                    if (order.id == this.currentItem.id) {
                                        order.status = this.currentItem.status
                                        order.date_complete = this.currentItem.date_complete
                                    }
                                    return order
                                })
                            } else {
                                console.log('order saved fail')
                            }
                            this.dialogStatus = false
                        })
                        .catch(err => {
                            console.log(err)
                            this.dialogStatus = false
                        })
                }
            },
            onClickOrderDelete() {
                this.currentItem.status = config.order.status.deleted

                this.$store
                    .dispatch('order_set', this.currentItem)
                    .then(res => {
                        if (res.data.success) {
                            console.log('order saved ok')
                            this.orders = this.orders.filter(order => order.id != this.currentItem.id)
                        } else {
                            console.log('order saved fail')
                        }
                        this.dialogStatus = false
                    })
                    .catch(err => {
                        console.log(err)
                        this.dialogStatus = false
                    })
            },
            onClickChangeDate(arg) {
                this.updateData()
            },
            onClickDownloadData() {
                console.log('settings', this.$store.state.settings)
                let url = `${ config.api.delivery }orders_export?tz=${ this.$store.state.timezone }&dispatcher_tid=${ this.$store.state.user.tid }&date1=${ this.dates[0] }&date2=${ this.dates[1] } 23:59:59&partner=${ this.filter_partner }&courier=${ this.filter_courier }&mode=${ this.filter_order_mode }&deliveryTime=true`
                window.location.href = url
            },
            onClickToday() {
                this.dates[0] = moment().format('YYYY-MM-DD')
                this.dates[1] = moment().format('YYYY-MM-DD')
                this.updateData()
            },
            onClickYesterday() {
                this.dates[0] = moment()
                    .subtract(1, 'd')
                    .format('YYYY-MM-DD')
                this.dates[1] = moment()
                    .subtract(1, 'd')
                    .format('YYYY-MM-DD')
                this.updateData()
            },
            onClickMonth() {
                this.dates[0] = moment()
                    .subtract(1, 'M')
                    .format('YYYY-MM-DD')
                this.dates[1] = moment().format('YYYY-MM-DD')

                this.updateData()
            },
            onClickUpdate() {
                this.updateData()
            },
            getGuestStr(guest) {
                let splittedStr = guest.split('Телефон:')
                return splittedStr.length > 1 ? splittedStr[0] + ' ' + splittedStr[1].substr(0, 15) : guest
            },
            getTdCatted(str) {
                return '<td>Hello</td>'
            },
            partnerNameGet: function(partner_tid) {
                let partner_name = '--'
                const partner = this.partners.find(partner => partner.tid == partner_tid)
                if (partner) {
                    partner_name = partner.partner_name
                }
                return partner_name
            },
            // courierNameGet: function(courier_tid) {
            //     let courier_name = '--'
            //     const courier = this.couriers.find(courier => courier.tid == courier_tid)
            //     if (courier) {
            //         courier_name = courier.tname
            //     }
            //     return courier_name
            // },
            orderStatusColorsHtml() {
                return [this.orderStatusColorHtml(0), this.orderStatusColorHtml(1), this.orderStatusColorHtml(2), this.orderStatusColorHtml(3), this.orderStatusColorHtml(4), this.orderStatusColorHtml(5)]
            },
            getRandomInt() {
                return Math.floor(Math.random() * (50 - 5 + 1)) + 5
            },
            updateData() {
                this.loading = true
                console.log('this.filter_courier', this.filter_courier)
                this.$store
                    .dispatch('orders_date', {
                        date1: this.dates[0],
                        date2: this.dates[1] + ' 23:59:59',
                        partner: this.filter_partner,
                        courier: this.filter_courier,
                        mode: this.filter_order_mode,
                        deliveryTime: true,
                    })
                    .then(res => {
                        if (res.data.success) {
                            this.orders = res.data.orders.reverse()
                            let fee = 0
                            for (let order of this.orders) {
                                if (order.date_complete) {
                                    const f = parseInt(order.fee)
                                    if (f) fee += f
                                    // fee += +order.fee
                                }
                            }
                            this.ordersFee = fee

                            console.log('orders_date', this.orders)
                            const ordersComplete = this.orders.map(order => order.state == config.order.status.complete)
                            const ordersNotComplete = this.orders.map(order => order.state != config.order.status.complete)
                            // this.ordersCountToday = ordersComplete.length
                            this.orderStatuses[config.order.status.forming] = 0
                            this.orderStatuses[config.order.status.new] = 0
                            this.orderStatuses[config.order.status.passed] = 0
                            this.orderStatuses[config.order.status.way_partner] = 0
                            this.orderStatuses[config.order.status.way_guest] = 0
                            this.orderStatuses[config.order.status.complete] = 0

                            this.orders.forEach(order => {
                                this.orderStatuses[order.status]++
                            })

                            this.ordersComplete = this.orderStatuses[config.order.status.complete]
                        } else {
                            // this.ordersCountToday = 0
                            this.ordersComplete = 0
                            this.ordersFee = 0
                        }
                        this.loading = false

                        this.$store
                            .dispatch('partners')
                            .then(res => {
                                if (res.data.success) {
                                    this.partners = res.data.partners

                                    console.log('partners', this.partners)
                                }

                                this.filter_partners = this.orders.map(order => ({ tid: order.partner_tid, name: this.partnerNameGet(order.partner_tid) }))
                                this.filter_partners.splice(0, 0, { tid: 0, name: '< Все >' })
                                
                                this.$store
                                    .dispatch('couriers_get')
                                    .then(res => {
                                        if (res.data.success) {
                                            this.couriers = res.data.couriers

                                            console.log('couriers', this.couriers)
                                        }

                                        this.couriers_list = this.couriers.map(courier => ({ tid: courier.tid, name: courier.tname }))
                                        this.couriers_list.splice(0, 0, { tid: null, name: '< Не назначен >' })

                                        this.filter_couriers = this.orders.map(order => ({ tid: order.courier_tid, name: order.courier_name }))
                                        // this.filter_couriers = this.orders.map(order => ({ tid: order.courier_tid, name: this.courierNameGet(order.courier_tid) }))
                                        this.filter_couriers.splice(0, 0, { tid: 0, name: '< Все >' })
                                        this.filter_couriers.splice(1, 0, { tid: null, name: '< Не назначен >' })
                                        console.log('filter_couriers', this.filter_couriers)

                                    })
                                    .catch(err => {
                                        console.log(err)
                                    })

                                // this.$store
                                //     .dispatch('couriers_all_get')
                                //     .then(res => {
                                //         if (res.data.success) {
                                //             this.couriers = res.data.couriers

                                //             console.log('couriers', this.couriers)
                                //         }

                                //         this.couriers_list = this.orders.map(order => ({ tid: order.courier_tid, name: order.courier_name }))
                                //         this.couriers_list.splice(0, 0, { tid: null, name: '< Не назначен >' })

                                //         this.filter_couriers = this.orders.map(order => ({ tid: order.courier_tid, name: order.courier_name }))
                                //         // this.filter_couriers = this.orders.map(order => ({ tid: order.courier_tid, name: this.courierNameGet(order.courier_tid) }))
                                //         this.filter_couriers.splice(0, 0, { tid: 0, name: '< Все >' })
                                //         this.filter_couriers.splice(1, 0, { tid: null, name: '< Не назначен >' })
                                //         console.log('filter_couriers', this.filter_couriers)

                                //     })
                                //     .catch(err => {
                                //         console.log(err)
                                //     })
                            })
                            .catch(err => {
                                console.log(err)
                            })

                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            timeError(time1, time2) {
                let diff = ''
                if (time1 && time2) {
                    diff = moment(time1).diff(time2, "minutes")
                }
                let time = ''
                let sign = '+'
                if (diff < 0) {
                    sign = '-'
                    diff = -diff
                }
                let mins = 0
                let hours = 0
                for (; diff > 60; diff -= 60) {
                    hours++
                }
                mins = diff
                time += sign
                if (hours) {
                    time += hours + ' ч '
                }
                if (mins) {
                    time += mins + ' м '
                }
                return time
            },
            timeColorHtml(time1, time2) {
                let color
                if (time1 && time2) {
                    let diff = moment(time1).diff(time2, "minutes")
                    if (diff > 10) {
                        color = '#F44336' // red
                    } else {
                        color = '#4CAF50' // green
                    }
                } else {
                    color = '#607D8B' // blue-grey
                }
                return color
            },
            timeStrGet(date) {
                const isToday = moment().isSame(moment(date), 'day')
                let dateRes = ''
                if (isToday) {
                    dateRes = moment(date).format('HH:mm')
                } else {
                    dateRes = moment(date).format('D MMM HH:mm')
                }
                if (dateRes == 'Invalid date') dateRes = ''
                return dateRes
            },
            iconSource(order) {
                let icon = ''
                if (order.iikoOrderId) {
                    icon = `${ require('../../assets/images/iiko-48.jpg') }`
                } else {
                    icon = `${ require('../../assets/images/telegram-48.jpg') }`
                }
                return icon
            },
            tooltipSource(order) {
                let tooltip = ''
                if (order.iikoOrderId) {
                    tooltip = 'iiko'
                } else {
                    tooltip = 'Telegram bot'
                }
                return tooltip
            },
            orderStatusColorHtml(sta) {
                let color = 'grey'
                switch (sta) {
                    case config.order.status.new:
                        color = '#9C27B0' // purple
                        break
                    case config.order.status.way_partner:
                        color = '#FF9800' // orange
                        break
                    case config.order.status.way_guest:
                        color = '#2196F3' // blue
                        break
                    case config.order.status.complete:
                        color = '#4CAF50' // green
                        break
                    case config.order.status.forming:
                        color = '#607D8B' // blue-grey
                        break
                    case config.order.status.passed:
                        color = '#F44336' // red
                        break
                }
                return color
            },
            orderStatusColor(sta) {
                let color = 'grey'
                switch (sta) {
                    case config.order.status.new:
                        color = 'purple'
                        break
                    case config.order.status.way_partner:
                        color = 'orange'
                        break
                    case config.order.status.way_guest:
                        color = 'blue'
                        break
                    case config.order.status.complete:
                        color = 'green'
                        break
                    case config.order.status.forming:
                        color = 'grey'
                        break
                    case config.order.status.passed:
                        color = 'red'
                        break
                }
                return color + '--text'
            },
            orderStatusIcon(sta) {
                let icon = 'mdi-question'
                switch (sta) {
                    case config.order.status.new:
                        icon = 'mdi-flash'
                        break
                    case config.order.status.way_partner:
                        icon = 'mdi-run-fast'
                        break
                    case config.order.status.way_guest:
                        icon = 'mdi-run-fast'
                        break
                    case config.order.status.complete:
                        icon = 'mdi-check-bold'
                        break
                    case config.order.status.forming:
                        icon = 'mdi-account-question'
                        break
                    case config.order.status.passed:
                        icon = 'mdi-arrow-right-bold'
                        break
                }
                return icon
            },
            orderStatusStr(sta) {
                let status = 'Неизвестно'
                switch (sta) {
                    case config.order.status.new:
                        status = 'Новый'
                        break
                    case config.order.status.way_partner:
                        status = 'В пути к партнеру'
                        break
                    case config.order.status.way_guest:
                        status = 'В пути к гостю'
                        break
                    case config.order.status.complete:
                        status = 'Доставлен'
                        break
                    case config.order.status.forming:
                        status = 'Создается'
                        break
                    case config.order.status.passed:
                        status = 'Передан курьеру'
                        break
                }
                return status
            },
            rowItemClass(item) {
                return item.status == config.order.status.forming ? 'rowItemDisable' : ''
            }
        },
    }
</script>

<style lang="stylus">


    .rowItemDisable
        cursor default !important
        background #fff !important

        [role="button"]
            cursor default !important

    .orders
        height 100%

        // .text-nowrap
        //     word-wrap normal
        //     overflow hidden
        //     white-space nowrap

        .v-card
            cursor default

        .v-toolbar
            border-bottom-left-radius 0 !important
            border-bottom-right-radius 0 !important

        .v-data-table
            padding 0 16px
            .v-data-table__wrapper > table > thead > tr
                th
                    padding 0 8px

            .v-data-table__wrapper > table > tbody > tr
                cursor pointer
                td
                    padding 0 8px
                    font-size 0.75rem
                    p
                        margin-top 6px
                        margin-bottom 6px
                        height 22px
                    td.tooltip-text
                        padding-left 0
                    td
                        padding-left 0

        .v-tooltip__content
            background #222
            color #fff
            border-radius 6px
            padding 5px 10px 4px
            // &:hover
            //     cursor inherit

        .v-chip
            &.v-size--default
                font-size 0.75rem
                height 20px
                border-radius 16px
                margin 4px
                cursor inherit

        b
            font-weight 500

</style>
